@import '../_variables';

.dot:before {
  content: '';
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border-radius: 100%;
  margin-right: .5rem;
  margin-bottom: .15em;
  background: @color-default-4;
}

.dot-primary:before {
  background: @color-primary-3;
}

.dot-info:before {
  background: @color-info-3;
}

.dot-danger:before {
  background: @color-danger-3;
}

.dot-warning:before {
  background: @color-warning-3;
}

.dot-success:before {
  background: @color-success-3;
}

.dot-rainbow:before {
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
  opacity: .6;
  width: 10px;
  height: 10px;
  margin-bottom: 0;
}
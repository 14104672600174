@import '../_variables';
@import '_dot';

#product-wholesaler-modelings {
  @media (@min-screen-md) {
    background-color: @color-default-2;
    border-radius: @radius-default;
    padding: 1rem;
  }
}

.product-wholesaler-modeling {
  flex-wrap: wrap;

  .product-wholesaler-modeling-title {
    text-align: center;
    flex: 0 0 100%;
  }

  @media (@max-screen-md) {
    background-color: @color-default-2;
    border-radius: @radius-default;
    padding: 1rem;
  }

  @media (@min-screen-md) {
    flex-wrap: nowrap;

    .product-wholesaler-modeling-title {
      text-align: start;
      flex: 0 0 200px;
      margin: 0;
    }
  }
}

#product-wholesaler-sizes {
  display: flex;
  flex-wrap: wrap;

  @media (@min-screen-md) {
    flex-wrap: nowrap;
  }

  .product-wholesaler-size {
    @media (@max-screen-md) {
      flex: 1 0 50% !important;
    }

    label {
      line-height: 2rem;
    }
  }

  .d-flex {
    align-items: center;
    justify-content: center;

    label {
      max-width: 6rem;
    }

    input[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}

.product-wholesaler-size {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

#product-wholesaler-summary {
  @media (@max-screen-xs) {
    flex-basis: 100%;
    text-align: center;
  }
}

#product-buy-button-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;

  .btn {
    flex: 1 0 auto;
    // max-width: 15rem;
  }

  #product-wishlist-mobile-icon {
    background-color: @color-default-2;
    padding: 0.5rem;
    border-radius: 50%;

    .icon-cr {
      height: 1.3em;
      width: 1.6em;
    }
  }

  #product-alerts {
    flex-basis: 100%;
  }
}

.product-combo {
  flex-direction: column;

  @media (@min-screen-md) {
    flex-direction: row;
    align-items: center;
  }
}

.price-in-combo{
  border-left: 1px solid  #E5702C;
  background: #F7F6F5;
  padding: 8px 7px 8px 5px;
  
  @media(@max-screen-xs){
    margin-left: -1rem;
    margin-right: -1rem;
  }

  h3 {
    margin: 0;
  }

  p{
    font-size: 13px;
    margin: 0;
  }

}

@media (@min-screen-md) {
  .product-combo-regular-price,
  .product-combo-divider {
    padding-top: 1.7rem;
  }
}

.product-combo-divider {
  margin: 1rem 0;

  @media (@min-screen-md) {
    margin: 0 1rem;
  }
}

.product-price-frame-content {
  border: 1px solid @color-default-4;
  border-bottom-left-radius: @radius-default;
  border-bottom-right-radius: @radius-default;
  border-top-right-radius: @radius-default;
  flex-direction: column;

  > :nth-child(2) {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px dashed @color-primary-5;
  }

  @media (@min-screen-lg) {
    flex-direction: row;
    align-items: center;

    > :nth-child(2) {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
      border-left: 1px dashed @color-primary-5;
    }
  }
}

.product-price-frame-badge {
  line-height: 1.7rem;
  background-color: @color-default-4;
  border-top-left-radius: @radius-default;
  border-top-right-radius: @radius-default;
}

.product-price-frame-primary .product-price-frame-content {
  border-color: @color-primary-5;
}

@media (@max-screen-md) {
  .withpricebadge{
    display: block !important;
    padding-top: 0 !important;
  }

  .hiddenfixedbutton {
    bottom: -145px !important;
  }
}

.product-price-promo.mobile-badge-price{
  font-size: 1rem !important;
  margin-top: 0;
}

.open-rules-mobile{
  margin-top: -3px;
  height: 35px;
  width: 35px;
  float: right;
}

.green-button{
  background-color: @color-success-5;
  color: #201f1e;
}

@media (hover: hover) and (pointer: fine){
  .green-button:not([disabled]):not(.btn-disabled):hover {
    background-color: #117332;
  }
}

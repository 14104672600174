@import '../_variables';

.cr-option-group {
  display: flex;
  flex-wrap: wrap;

  .cr-option {
    flex: 0 0 auto;

    &:not(:last-child) {
      margin-right: 0.4rem;
    }
  }

  @media (@max-screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cr-option {
  font-size: 14px;
  position: relative;
  border: 1px solid @color-default-3;
  // border: @input-border-width solid @color-default-2;
  // background-color: @color-default-2;
  background-color: transparent;
  color: @color-default-5;
  border-radius: @radius-default;
  text-align: center;
  flex: 0 0 @input-height-xs;
  height: @input-height-xs;
  line-height: calc(@input-height-xs);
  user-select: none;
  transition: border-color @transition-default,
  background-color @transition-default, color @transition-default;

  &.cr-option-squared,
  &.cr-option-rounded {
    min-width: @input-width-cr-option-xs;
    min-height: @input-height-xs;
    // min-width: @input-height-xs;
  }

  &:not(.cr-option-squared) {
    padding: 1px 1.3rem;
  @max-screen-sm {
    padding: 1px 1rem;
  }
  }

  .cr-option-description {
    position: absolute;
    bottom: -1.3em;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.4;
    white-space: nowrap;

    &.cr-option-description-hover {
      visibility: hidden;
    }
  }

  @media (@min-screen-sm) {
    flex-basis: @input-height-sm;
    line-height: calc(@input-height-sm - 2 * 0);

    &.cr-option-squared,
    &.cr-option-rounded {
      // min-width: @input-height-sm;
      min-width: 5.8rem;
    }

    &.cr-option-rounded {
      border-radius: calc(@input-height-sm / 2);
    }
  }

  @media (hover: hover) and (@min-screen-xs) {
    cursor: pointer;

    &:hover {
      &:not(.cr-option-disabled) {
        border-color: @color-info-4;
      }

      .cr-option-description-hover {
        visibility: visible;
        transition: visibility @transition-duration @transition-curve;
      }
    }

    &:active {
      border-color: @color-default-4;
    }
  }

  &.cr-option-selected {
    // border-color: @color-info-4;
    // background: @color-info-4;
    border-color: @color-default-5;
    background: @color-default-5;
    color: white;

    .cr-option-description {
      // color: @color-info-4;
      color: @color-default-5;
    }
  }


}

.cr-option-rounded {
  border-radius: calc(@input-height-xs / 2) !important;
}

.cr-option-disabled {
  color: @color-default-3;
  background-color: @color-border-default;
  border-color: @color-border-default;
}

.cr-option-crossed {
  &:after {
    content: '';
    position: absolute;
    height: 215%;
    top: -24px;
  }

  &:after {
    width: 1px;
    left: 50%;
    background: @color-default-text-3;
    transform: translateX(-35%) rotate(118deg);
    mix-blend-mode: difference;
  }
}

.product-variations-colors {
  .product-color-option {
    width: 100%;
    height: 100%;
  }

  .cr-option {
    background-color: white;
    padding: 0;
    border-radius: 0;
    min-width: 2.5rem;
    min-height: 2.5rem;
    height: 2.5rem;
  }
}


.with-border {
  border: 2px solid @color-default-5;
}

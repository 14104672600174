@import '../_variables';

@image-list-height: 120px;
@image-list-height-xs: 77px;

@image-list-width: 100px;
@image-list-width-xs: 64px;

@icon-nav-size: 1.5rem;

#product-images {
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;

  > #product-image {
    text-align: center;

    @media (min-width: 426px) {
      // 425 is the image width
      .image-zoom-container {
        border-radius: @radius-default;
        overflow: hidden;
      }
    }

    > div {
      position: relative;
      // max-width: 425px;
      margin: 0 auto;

      .icon-cr {
        position: absolute;
        top: 1rem;
      }

      .icon-question-sign {
        right: 1rem;

        @media (@max-screen-sm) {
          right: 6rem;
        }
      }

      .icon-wishlist {
        right: 3.5rem;

        @media (@max-screen-sm) {
          right: 3.5rem;
        }
      }

      .icon-zoom-in,
      .icon-zoom-out {
        right: 1rem;

        @media (hover: hover) and (pointer: fine) {
          pointer-events: none;
        }
      }

      .product-image-pinterest {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
    }
  }

  #product-image-touch-zoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: @header-z-index + 1;

    .image-pinch {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    p {
      margin: 0.5rem;
      padding: 0.5rem;
      background-color: @color-default-3;
      border: 1px solid white;
      border-radius: @radius-default;
      color: white;
      white-space: nowrap;
      opacity: 0.7;
    }

    .icon-pinch {
      width: 2em;
      height: 2em;
    }
  }

  @media (@min-screen-md) {
    flex-direction: row;
    user-select: none;

    > #product-image {
      flex: 1 1 auto;
      margin: 0 0.555rem;
    }
  }
}

#product-images-list-container {
  position: relative;

  #product-images-list-previous,
  #product-images-list-next {
    position: absolute;
    text-align: center;
    height: @icon-nav-size;
    cursor: pointer;
    border-radius: @radius-default;
  }


  @media (@max-screen-md) {
    height: @image-list-height-xs;
    overflow-y: hidden;
    margin-top: 1rem;
    padding-left: calc(@icon-nav-size + 0.5rem);
    padding-right: calc(@icon-nav-size + 0.5rem);

    #product-images-list-previous,
    #product-images-list-next {
      top: 0;
      height: 100%;
      background-color: @color-default-1;

      .icon-cr {
        position: relative;
        top: calc(50% - (@icon-nav-size / 2));
      }
    }

    #product-images-list-previous {
      left: 0;
    }

    #product-images-list-next {
      right: 0;
    }
  }

  @media (@min-screen-md) {
    overflow: visible;
    // flex: 1 0 @image-list-width-xs;
    // max-width: @image-list-width;
    #product-images-list-previous,
    #product-images-list-next {
      width: 100%;

      .icon-cr {
        position: absolute;
        background-color: #fff;
        width: 2.5rem;
        height: 1.5rem;
      }
    }

    #product-images-list-previous {
      top: -@icon-nav-size;

      .icon-cr {
        margin-top: 25px;
      }
    }

    #product-images-list-next {
      bottom: -@icon-nav-size;

      .icon-cr {
        margin-top: -25px;
      }
    }
  }
}

#product-images-list-scroll {
  @media (@max-screen-md) {
    overflow-x: auto;
    overflow-y: hidden;

    @media (hover: none), (pointer: coarse) {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch; // iOS smooth scroll / momentum
    }
  }

  @media (@min-screen-md) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    @media (hover: none), (pointer: coarse) {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; // iOS smooth scroll / momentum
    }
  }
}

ul#product-images-list {
  position: relative;
  list-style: none;
  padding-left: 0;
  display: flex;

  @media (@min-screen-md) {
    align-content: center;
    flex-direction: column;
    height: 0;
    transition: top @transition-default;
  }

  li {
    overflow: hidden;
    // opacity: 0.4;
    transition: opacity @transition-default;
    position: relative;

    &.active {
      opacity: 1;
    }

    .active-open {
      border: 15px solid @color-border-default;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    @media (@max-screen-md) {
      flex: 0 0 @image-list-width-xs;
      height: @image-list-height-xs;
      margin-right: 0.5rem;
      text-align: center;
    }

    @media (@min-screen-md) {
      flex: 0 0 auto;
    }

    img {
      max-width: 100%;
      width: 100%;
    }
  }
}

@import '../_variables';

.image-zoom-container {
  position: relative;
  display: block;
  cursor: crosshair;
  pointer-events: none;

  @media (hover: hover) and (pointer: fine) {
    pointer-events: auto;
  }

  &.isvideo {
    pointer-events: auto;
  }

  .image-zoom-placeholder {
    overflow: hidden;
    height: 0;
    padding-bottom: percentage(600/400);
    background-color: @color-backgound-default;
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .image-zoom-lens {
    position: absolute;
    border: 1px solid @color-default-2;
    width: 3rem;
    height: 3rem;
  }

  .image-zoom-result {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

@import '../../submodules/src/styles/aspect';
@import '../../submodules/src/styles/layout';
@import '../../submodules/src/styles/flex-grid';
@import '../../submodules/src/styles/badge-pill';
@import 'element/_product-images';
@import 'element/_icons-mixins';
@import 'element/_image-zoom';
@import 'element/_cr-option';
@import 'element/_product-buy';
@import 'element/_dot';

.icon-wishlist();
.icon-info-product();
.icon-zoom-in();
.icon-eye-open();
.icon-mug();
.icon-pillow();
.icon-ecobag();
.icon-scarf();
.icon-info();

html {
  scroll-behavior: smooth;
}

section#product {
  .opened {
    display: block;
  }

  .hidden-opened .opened {
    @media (@min-screen-md) {
      display: block;
    }

    @media (@max-screen-md) {
      display: none;
    }
  }

  .grayColor {
    @media (@max-screen-md) {
      background-color: var(--color-light-shade-gray);
      padding: 21px 20px;
      color: var(--color-white) !important;
      th {
        color: var(--color-white) !important;
        font-weight: 100;
      }
    }
  }

  // Somente para esta página
  .m0 {
    @media (@max-screen-md) {
      margin: 0 0 2px !important;
    }
  }

  // Somente para esta página
  .p0 {
    @media (@max-screen-md) {
      padding-bottom: 0 !important;
    }
  }

  // Remove content from "Content class"
  .rmc {
    @media (@max-screen-md) {
      padding: 0;
    }
  }

  .chip-mobile {
    @media (@max-screen-md) {
      color: var(--color-white);
      border: 2px solid var(--color-white);
    }
  }

  .product-model-select {
    width: 14rem;
  }

  .cr-option-group {
    margin-bottom: -0.5rem;

    .cr-option {
      margin-bottom: 0.5rem;
    }
  }

  .cr-option-rainbow-dot:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    transform: translate(25%, -40%);
    background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
    z-index: 1;
    opacity: 0.6;
  }

  .link-title-size-guide {
    width: 100%;
    justify-content: space-between;
  }

  #product-main-wrapper {
    @media (@max-screen-md) {
      padding: 0;
    }

    @media (@min-screen-md) {
      margin-top: 1rem;
    }

    @media (@min-screen-md) {
      margin-bottom: 2rem;
    }

    #product-main {
      align-items: flex-start;
    }
  }

  #product-images {
    @media (@max-screen-md) {
      // max-width: 425px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #product-main-right {
    flex-grow: 1;

    .product-variations-types {
      max-width: fit-content;
    }

    .product-variations-types > :first-child {
      .cr-option-group {
        @media (@min-screen-md) {
          flex-direction: column;
        }
        @media (@max-screen-md) {
          margin-top: 1rem;
        }
      }
    }

    .product-price > :first-child,
    .product-price .h1,
    .product-variations-types > :first-child .h6 {
      margin-top: 0;
    }

    @media (@max-screen-md) {
      > :first-child {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    //@media (@min-screen-xs) and (@max-screen-md) {
    //    position: relative;
    //    padding: 1rem 0;
    //    margin-top: 2rem;
    //
    //    &:not(.product-buy-wholesaler) {
    //        display: flex;
    //
    //        #product-info hr {
    //            display: none;
    //        }
    //    }
    //
    //    &:before {
    //        position: absolute;
    //        content: '';
    //        top: 0;
    //        left: 50%;
    //        transform: translateX(-50%);
    //        height: 100%;
    //        width: 100vw;
    //        z-index: 1;
    //    }
    //
    //    > :first-child {
    //        position: relative;
    //        z-index: 2;
    //        flex: 1 1 auto;
    //
    //        hr {
    //            border-color: transparent;
    //        }
    //    }
    //
    //    #product-info {
    //        position: relative;
    //        z-index: 2;
    //        flex: 0 0 calc(50% - 0.5rem);
    //    }
    //}

    @media (@min-screen-md) {
      flex-grow: 0;

      #product-buy-button-container {
        flex-wrap: nowrap;
      }
    }
  }

  #product-main-right,
  #product-buy {
    @media (@min-screen-sm) and (@max-screen-md) {
      #product-buy-button-container {
        max-width: 689px;
      }
    }
    @media (@max-screen-md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  #product-name-container {
    display: flex;
    align-items: center;
    float: left;
    min-width: 71%;

    .icon-cr {
      width: 2rem;
      height: 2rem;
      margin-top: 0.25em;
    }

    @media (@max-screen-md) {
      justify-content: center;
      min-width: 0;

      #product-name {
        display: block;
      }
    }

    @media (@min-screen-md) {
      #product-title {
        margin-right: 1rem;
      }
    }

    @media (@min-screen-xs) and (@max-screen-md) {
    }
  }

  #product-advantages {
    margin: 1rem 0;
    width: 100%;
    flex-direction: column;
    gap: 1rem;

    .slider-product-advantage {
      height: 100%;
      line-height: 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 1s ease;

      @media (@min-screen-md) {
        width: 350px;
      }
      @media (@max-screen-md) {
        font-size: 12px;
        width: 100vw;
      }
      @media (max-width: 330px) {
        font-size: 11px;
      }

      .icon-cr {
        font-size: 2rem;
      }

      span {
        max-width: 400px;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }

    .slider-product-advantage.active {
      opacity: 1;
    }
  }

  .product-advantages-container {
    flex-direction: column;
  }

  @media (@max-screen-md) {
    #product-alerts {
      margin-top: 1rem;
      flex-basis: 100%;
    }
  }

  .product-price {
    h5 {
      font-size: 1.5rem;
    }
  }
}

.product-price-promo {
  display: block;
  color: white;
  padding: 0.45em 0.975em;
  font-size: 1.125rem !important;
}

.countdown-product {
  background-color: @color-border-default;
  color: @color-danger-1;
  margin-top: 0.5em;
  padding: 0.745em 0em;
  
  padding-left: 10%;
  line-height: 1.4rem;
  margin-bottom: 14px !important;
  &.blackfriday-countdown{
    padding-left: 5%;
  }
  .countdown{
    text-align: inherit;
  }
  .hours,
  .minutes,
  .seconds,
  .days {
    &:after {
      opacity: 1;
      font-size: 1.25rem;
    }
  }
}

.product-main-info {
  @media (@min-screen-md) {
    width: 90%;
    float: right;
  }

  .cr-option {
    height: 2.8rem;
    line-height: calc(2.6rem);
    font-size: 0.93rem;
  }

  .cr-option-modelings {
    flex-direction: unset !important;

    .cr-option {
      @media (@max-screen-sm) {
        min-width: calc((100vw / 2) - 1.5rem);
        padding: 1px 0.3rem;
        text-align: center !important;
      }
    }
  }

  .cr-option-description {
    bottom: -1.7em;
  }

  .title-infos {
    @media (@max-screen-sm) {
      font-weight: 700;
    }
  }

  .text-no-bold-mobile {
    @media (@max-screen-sm) {
      font-weight: 100 !important;
    }
  }

  .cr-option-sizes {
    .cr-option {
      line-height: calc(2.8rem);
      @media (@max-screen-sm) {
        width: calc((100vw / 4) - 0.9rem);
      }

      &.cr-option-crossed {
        @media (@max-screen-sm) {
          &:after {
            height: 176%;
            top: -39%;
          }

          &:after {
            transform: translateX(-35%) rotate(123deg);
          }
        }
      }
    }
  }

  .cr-option-colors {
    .cr-option {
      height: 2.5rem;
      @media (@max-screen-sm) {
        height: 3rem;
        width: 3rem;
      }
    }

    .cr-option-crossed {
      &:after {
        content: '';
        position: absolute;
        height: 126%;
        top: -4px;
      }

      &:after {
        width: 1px;
        left: 50%;
        background: @color-default-text-3;
        transform: translateX(-35%) rotate(134deg);
      }
    }
  }
}

.ts-shelf-container {
  .ts-sprite {
    background: url('@{cdn}/images/site/site-sprite.png?w=106');
  }

  .ts-shelf-rate-symbols {
    background-position: 0 -23px;
  }

  .ts-shelf-rate-enabled,
  .ts-shelf-rate-disabled {
    display: none;
  }
}

.widget-star-reviews {
  float: left;
  //margin-top: 0.9rem;
  //margin-bottom: 1.1rem !important;
  padding-right: 0.2rem;
  min-height: 1.563rem;
  @media (@max-screen-md) {
    margin-top: 0;
    margin-bottom: 0 !important;
    min-height: 23px;
  }

  &-line {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
}

.product-title-mobile {
  font-size: 1rem;
  padding-top: 0;
}

.product-title-desktop {
  font-size: 1.6em;
}

.container-tile-mobile {
  padding-bottom: 0.2rem;
}
